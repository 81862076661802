import { Box, Fab, Grid, Paper, Typography } from "@mui/material";
import FooterNav from "./FooterNav";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useEffect, useState } from "react";
import AddCardIcon from "@mui/icons-material/AddCard";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import theme from "../theme";
import Marquee from "react-fast-marquee";
import LogoutIcon from "@mui/icons-material/Logout";
import SliderComponent from "./SliderComponent";
import HomeCard from "./HomeCard";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
const HomePage = () => {
  const history = useNavigate();
  const [loginStatus, setLoginStatus] = useState(false);

  useEffect(() => {
    const mamberID = localStorage.getItem("mamberId");
    if (mamberID) {
      history("/");
      setLoginStatus(true);
      // toast('welcome back', {
      //   className: 'custom-toast'
      // });
    } else {
      setLoginStatus(false);
      history("/login");
    }
  }, []);

  const logOut = () => {
    localStorage.clear();
    setLoginStatus(false);
  };

  return (
    <>
      <div data-v-app="" style={{ fontSize: "12px",  }}>

        {/* nav */}

        <Box sx={{ flexGrow: 1, marginBottom: "5px", }}>
          <AppBar
            position="static"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              
                // border:'1px solid black',
              padding: "2px 10px",
            }}
          >
            <img src="/images/logo22.png" width="70px" alt="" />
            {/* <Box bgcolor="red"> */}

            <Typography variant="h6" style={{ marginLeft: '-5px', fontSize: '16px'}} color={theme.palette.primary.light}>
            उज्ज्वल साख सहकारी संस्था मर्यादित
            </Typography>
            {/* </Box> */}
            <Link to="/login">
              <Box
                style={{ borderColor: "#fff", color: "#fff" }}
                onClick={logOut}
              >
                <LogoutIcon />
              </Box>
            </Link>
          </AppBar>
        </Box>

        {/*Slider   */}
        <SliderComponent />
        <Paper
          elevation={5}
          style={{ display: "flex", padding: "5px", fontSize: "18px", borderRadius: "5px", margin: "10px 8px" }}
        >
          {/* <Grid
            style={{ padding: "5px", fontSize: "18px", borderRadius: "15px", display:'flex' }}
          > */}
            <NotificationsNoneIcon  color="primary"/>
            <Marquee speed={15}>
              Wellcome to&ensp;<span style={{color:"blue"}}>Ujjwal Sakh Sahakari Sanstha Maryadit</span>  ...&ensp;&ensp;   
            </Marquee>
          {/* </Grid> */}
        </Paper>

        <Box
          // elevation={3}
          style={{  padding: "5px", }}
        >
          <Typography color="primary" style={{ fontSize: "14px", fontWeight: 600,  }}>
            POLICY INFORMATION
          </Typography>
        </Box>

        <Box
          // elevation={5}
          style={{
            minHeight: "85px",
            margin: "4px 0",
            padding: "4px",
          }}
        >
          <Grid
            gap={0.7}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "10px 5px",
            }}
            xs={12}
          >
            <HomeCard link="/dds" src="/images/dds2.png" name="DDS" />
            <HomeCard link="/rd" src="/images/rd2.jpg" name="RD" />
            <HomeCard link="/fd" src="/images/fd.png" name="FD" />
            <HomeCard link="/loan" src="/images/loan.png" name="loan" />
            <HomeCard link="/#" src="/images/saving2.jpg" name="Saving" />
            <HomeCard link="/#" src="/images/th2.png" name="Calculator" />
            <HomeCard link="/#" src="/images/offer.png" name="Offer" />
            <HomeCard link="/#" src="/images/other3.png" name="Other" />
          </Grid>
        </Box>

        {/* <Paper
          elevation={5}
          style={{ margin: "15px 0",
            padding: "4px",
            //  backgroundColor: "#f2d7d7" 
            }}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            <Paper
              elevation={5}
              style={{
                background: "#b7a5c8",
                minWidth: "110px",
                height: "40px",
                borderRadius: "6px",
                color:'rgb(35 12 56)',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography>Button</Typography>
            </Paper>

            <Paper
              elevation={5}
              style={{
                background: "#b7a5c8",
                minWidth: "110px",
                height: "40px",
                borderRadius: "6px",
                display: "flex",
                color:'rgb(35 12 56)',
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography>Button</Typography>
            </Paper>

            <Paper
              elevation={5}
              style={{
                background: "#b7a5c8",
                color:'rgb(35 12 56)',
                minWidth: "110px",
                height: "40px",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography>Button</Typography>
            </Paper>
          </Grid>
        </Paper> */}

        <Box
    
          style={{ padding: "5px", }}
        >
          <Typography color="primary" style={{ fontSize: "14px", fontWeight: 600 }}>
            BILL PAYMENTS BY BBPS (Comming Soon...)
          </Typography>
        </Box>

        <Box
          // elevation={5}
          style={{
            minHeight: "85px",
            margin: "4px 0",
            padding: "4px",
          }}
        >
          <Grid
            gap={0.6}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "10px 5px",
            }}
            xs={12}
          >
            <HomeCard link="/#" src="/images/dds.png" name="Mobile" />
            <HomeCard link="/#" src="/images/dds.png" name="Broadband" />
            <HomeCard link="/#" src="/images/dds.png" name="Recharge" />
            <HomeCard link="/#" src="/images/dds.png" name="Bill payment" />
            <HomeCard link="/#" src="/images/dds.png" name="Scan & Pay" />
            <HomeCard link="/#" src="/images/dds.png" name="Elect. bill" />
            <HomeCard link="/#" src="/images/dds.png" name="Water bill" />
            <HomeCard link="/#" src="/images/dds.png" name="DTH" />

            {/* <AddCardIcon style={{ height: "50px", padding: "5px" }} /> */}

            {/* <PhoneAndroidIcon style={{ height: "50px", padding: "5px" }} /> */}

            {/* <AccountBalanceWalletIcon
                  style={{ height: "50px", padding: "5px" }}
                />
                */}

            {/* <AddCardIcon style={{ height: "50px", padding: "5px" }} /> */}

            {/* <QrCode2OutlinedIcon
                  style={{ height: "50px", padding: "5px" }}
                /> */}

            {/* <AccountBalanceWalletIcon
                  style={{ height: "50px", padding: "5px" }}
                /> */}
          </Grid>
        </Box>
        
        <Box style={{ marginTop: "60px" }}>
          <FooterNav />
        </Box>
      </div>
    </>
  );
};
export default HomePage;

import {
  Box,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import FooterNav from "./FooterNav";
import { useEffect, useState } from "react";
import axios from "axios";
import {  NavLink, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import NavBar from "./Navbar";


const RD = () => {
  const [data, setData] = useState([]);
  const [loding, setLoading] = useState(false);
  const navigate = useNavigate()
  const [dataAmount, setDataAmount] = useState(null);
  const [dataId, setDataId] = useState(null);

  const fetchAmountData = async (proid) => {
    try {
      const response = await axios
        .get(
          `https://ujjwalssm.in/api/function?action=policyinfonew&policyno=${proid}`
        )
        .then((res) => {
          if(res.status ==200){
            setDataAmount(res.data.totalpolicybalance);
            setDataId(res.data.policy_data[0].id)
          }
      
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const fetchData = async () => {
    const mamberID = localStorage.getItem("mamberId");
    try {
      const response = await axios
        .get(
          `https://ujjwalssm.in/api/function?action=rd&memberid=${mamberID}`
        )
        .then((res) => {
          setData(res.data);
          setLoading(true);
        });
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

const handleClick= ()=>{
  navigate('/statement')
}

  return (
    <div id="app" data-v-app="" style={{ fontSize: "12px" }}>
      <NavBar name ='RD Policy'/>

    {loding === false ? (
      <Box
        sx={{
          width: "100%",
          marginTop: "15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    ) : (
      <>
        {data.length > 0 ? (
          <div style={{margin:'5px' , marginTop:'10px'}}>
            {data.map((datas, ind) => {
              return (
                <Paper
                  elevation={3}
                  key={ind}
                  style={{
                    marginBottom: '2px',
                    padding: "10px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems:'center'
                    }}
                  >
                    <Typography
                      style={{ fontFamily: "serif", fontSize: "16px" }}
                    >
                      Policy no.- <span>{datas.id}</span>{" "}
                    </Typography>
                    <Typography
                      style={{ fontFamily: "serif", fontSize: "16px" }}
                    >
                     Policy Date <span>{datas.txtpDate}</span>
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems:'flex-end'
                      // margin: "15px",
                    }}
                  >
                      <Typography
                        style={{ fontFamily: "serif" }}
                        >
                        Maturity Date <span>{datas.txtMdate}</span>
                      </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems:'center'
                    }}
                    >
                    {dataId===datas.id ? <p style={{color:'green', 
                      fontWeight:700, display:'flex', margin:'0px',alignItems:'center', fontSize:'14px' ,
                      justifyContent:'center', minWidth:"80px"}}> {dataAmount} </p> :
                     <Button style={{ }} onClick={()=>fetchAmountData(datas.id)}> Balance </Button>}
                    <NavLink style={{textDecoration: "none"}} to={`/policydetails/${datas.id}`} >
                      <Button color="primary">Details</Button>
                      </NavLink>
                    <NavLink style={{textDecoration: "none"}} to={`/statement/${datas.id}`} >
                      <Button color="primary">Statements</Button>
                      </NavLink> 
                  </Box>
                </Paper>
              );
            })}
          </div>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              height: "200px",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontFamily: "serif",
                fontSize: "16px",
                fontStyle: "italic",
              }}
            >
              Record Not Found
            </p>
          </Box>
        )}
      </>
    )}
<Box style={{marginTop:'60px'}}>
<FooterNav />
</Box> 
  </div>
  );
};
export default RD;

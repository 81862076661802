
import NavBar from "./Navbar";
import FooterNav from "./FooterNav";
import {  Box, Button, Checkbox,   FormControlLabel, Grid,  TextField,  } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { useState } from "react";




const ChangePass =()=>{
    const [data, setData]= useState({
        password:null,
        newpassword:null,
        confirmpassword:null
      })
    // let navigate = useNavigate();
    // const [data, setData] = useState([]);
   
    const proid = useParams();

    const handleChange=(e)=>{
        setData({
         ...data,
         [e.target.name]:e.target.value
        })
     }

    const handleSubmit = async(event) => {
        event.preventDefault();
            try {
                const response = await axios.get(`https://ujjwalssm.in/api/function?action=reset&memberid=${proid.id}&oldpassword=${data.password}&newpassword=${data.newpassword}`)
                  .then((res)=>{
                    // console.log("tttttt",res)
                    if (res.data[0].status === "success") {
                    //   console.log("new data")
                      toast("Password Change Successfully", {
                        className: 'custom-toast'
                      });
                     
                  } else{
                    toast(res.data[0].status, {
                      className: 'custom-toast'
                    });
                  }
    
                  })
                 }catch (error){
                  console.log("Error",error)
        }
        
      };
    return(
        <>
        <NavBar name="Account Details" />
  
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Enter Old Password"
              autoFocus
              name="password"
              placeholder="Enter Old Password"
              type="text"
              id="password"
              onChange={handleChange}

            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Enter New Password"
              onChange={handleChange}
              name="newpassword"
              placeholder="Enter New Password"
              type="password"
              id="newpassword"

            />
             <TextField
              margin="normal"
              required
              fullWidth
              label="Confirm Password"
              onChange={handleChange}
              name="confirmpassword"
              placeholder="Confirm Password"
              type="text"
              id="confirmpassword"

            />
          
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, color:"#fff", fontSize:'18px' }}
            >
              Change Password
            </Button>
            <ToastContainer />

           
          </Box>
        <Box style={{ marginTop: "60px" }}>
          <FooterNav />
        </Box>
      </>
    )
}
export default ChangePass;